

import Vue from 'vue/dist/vue.js';
import App from './app.vue'
import VueRouter from 'vue-router'
import routes from './routes/routes'
import asyncComputed from 'vue-async-computed'
import VModal from 'vue-js-modal'

Vue.config.productionTip = true;


//npm plugins
Vue.use(VueRouter);
Vue.use(asyncComputed);
Vue.use(VModal);


//routes
const router = new VueRouter ({
    routes: routes,
    linkActiveClass: "active",
    mode: "history",
    scrollBehavior(to,from,savedPosition) {
        return{left: 0, top: 0}
       
    }
})




//import store from './store/store.js'


import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate"
//import storedvalues from './store/store.js'
Vue.use(Vuex)




//store
const store = new Vuex.Store ({
    plugins: [createPersistedState()],
    state: {
        currentot: 1,
        betfaircom: 5,
        betdaqcom: 2,
        smarketcom: 2,
        timediff: 0,
        tzdesc: '',
        authtype: '',
        email: '',
        username: '',
        signedin: false,
        country: '',
        sb: [], //selectedbookmakers
        nameid: 0,
        sports: [],
        oats: [],
        selectedoat: 0,
        adbanners: [],
        filterbysport: [],
        countryname: '',
        ipaddress: '',
        regbody: [], //selected regbodies to select bookmakers from 
        popcomps: [],
        favourites: [],
        users: [],
        countryiso: 'EN',
        currency: '',
        isadmin: false,
        obfuscatedname: '',
        sitename: 'Oddsgalore',
        bespokebookies: false,
        sessiontime: '',
        showhints: false,
        advertsloaded: false,
        nopromo: -99,
        langtranslationfile: [],
        langfileloaded: false,
        leftside: false,
        rightside: false
        
    },

    mutations: {
        setCurrentot(state, val){
            state.currentot = val
        },
        setBetfaircom(state, val){
            state.betfaircom = val
        },
        setBetdaqcom(state, val){
            state.betdaqcom = val
        },
        setSmarketcom(state, val){
            state.smarketcom
        },
        setTimediff(state, val){
         
            state.timediff = val
        },
        setTzdesc(state, val){
            state.tzdesc = val
        },
        setAuthtype(state, val){
            state.authtype = val
        },
        setEmail(state, val){
            state.email = val
        },
        setName(state, val){
            state.username = val
        },
        setSignedin(state, val){
            state.signedin = val
        },
        setCountry(state, val){
            state.country  = val
        },
        setSelectedbookmakers(state,val){
            state.sb = val
        },
        setNameID(state, val){
            state.nameid = val
        },
        setSports(state, val){
            state.sports = val
        },
        setOatTypes(state, val){
            state.oats = val
        },
        setOat(state, val){
            state.selectedoat = val
        },

        setAdbanners(state, val){
            state.adbanners = val
        },
        setFilterbysport(state, val){
            state.filterbysport = val
        },
        setCountryName(state, val){
        
            state.countryname = val
        },
        setIPAddress(state,val) {
           
            state.ipaddress = val
        },
        setRegBody(state,val) {
           
            state.regbody = val
        },
        setPopComps(state,val) {

            state.popcomps = val
        },
        setFavourites(state,val) {

            state.favourites = val
        },
        setUsers(state,val) {

            state.users= val
        },
        setCountryISO(state,val) {
            state.countryiso = val
        },
        setCurrency(state,val) {
            state.currency = val
        },
        setisAdmin(state,val) {
            state.isadmin = val
        },
        setObfuscatedName(state,val) {

            state.obfuscatedname = val
        },

        setSiteName(state,val) {

            // Vue.set(state,'nopromo', val)
            state.sitename = val
        },

        setBespokeBookies(state,val) {

           // Vue.set(state,'nopromo', val)
            state.bespokebookies = val
        }, 
        
        setSessionTime(state,val) {
            state.sessiontime = val
        },

        setShowHints(state,val) {

            Vue.set(state,'showhints', val)

        },

        setAdvertsLoaded(state,val) {
    
            Vue.set(state,'advertsloaded', val)

        },

        setNoPromo(state,val) {

            Vue.set(state,'nopromo', val)

        },

        setLangTranslationFile(state,val) {
            
            Vue.set(state,'langtranslationfile', val)
        },

        setLangFileLoaded(state,val) {
            Vue.set(state,'langfileloaded', val)
        },

        setLeftSide(state,val) {
            Vue.set(state,'leftside', val)
        },

        setRightSide(state,val) {
            Vue.set(state,'rightside', val)
        }


    },
    actions: {

    },
    getters:{
       


    },



})

// confirm box
import confirmdialog from 'vue-confirm-dialog';
Vue.use(confirmdialog)

import globalmethods from './components/plugins/globalmethods.js';
Vue.use(globalmethods)




new Vue({
    el: '#app',
    router,
    store,
    data:{
        quad: true
    },
    render: h => h(App)
})








   

