<template>

<div>
    
        <div class="show-for-medium" style="min-height: 70px;background-color: #0d0b38;">

            <bannerad :bannertype="colorandBannerHTML(1)" bannerdivheight="70px" :key="0"  actualbanbackcolour="#0d0b38" v-if="colorandBannerHTML(1) != ''"></bannerad>
        
        </div>
        
        <navbar :sportswithodds="$store.state.sports" v-if="$store.state.langfileloaded"></navbar> 

        <div class="cell hide-for-large">

         <menuitems  :templatetype="1" v-if="$store.state.langfileloaded" ></menuitems>

        </div>
        <!--Main Body - do not put in div tags as knackers the XY Grid*-->

            <div class="grid-x align-center" >

                <div id="leftside" class="cell xxlarge-2 show-for-xxlarge" style="background-color: #0d0b38;">

                    <bannerad :bannertype="colorandBannerHTML(2)" :key="1"  actualbanbackcolour="#0d0b38" bannerdivheight="100%" v-if="colorandBannerHTML(2) != ''"></bannerad>

                </div>

                <div class="cell large-12 xlarge-10 xxlarge-8">

                    <popup></popup>
                    <vue-confirm-dialog></vue-confirm-dialog>

                    <div class="cell show-for-large">

                        <features type="across" v-if="$store.state.langfileloaded"></features>
                    </div>


                    <div class="grid-x" >

                       <div id="dropdownmenu" class="cell shrink show-for-large " >
                            <menuitems cn="vertical menu icons icons-left"  :templatetype="0" v-if="$store.state.langfileloaded"> </menuitems>

                        </div>

                        <!-- <template v-if="errorobject.error">
                            <errorhandler :errorobject="errorobject"></errorhandler
                        </template> -->


                       <div class="cell auto" style="min-height:100vh">
                       <template v-if="$store.state.langfileloaded">
                           <router-view ></router-view>
                        </template>
                        </div>
                    </div>

                </div>


                <div id="rightside" class="cell xlarge-2  show-for-xlarge"  style="background-color: #0d0b38;">
                <bannerad :bannertype="colorandBannerHTML(2)" :key="2"  actualbanbackcolour="#0d0b38" bannerdivheight="100%" style="background-color: #0d0b38;" v-if="colorandBannerHTML(2) != ''"></bannerad>
                </div>

           </div>

           <div class="cell" style="background-color:lightgray;border-top:1px solid darkslategray">
            <ul style="list-style-type: none;padding:40px;text-align: center;font-size:0.75rem">
                <li>
                    <router-link @click.native="scrollToTop" :to="{ name: 'kitviewer' }" class="qgreylink">{{ langConv('Kit Sizes') }}</router-link>
                </li>
                <li>
                    <router-link @click.native="scrollToTop" :to="{ name: 'feedback' }" class="qgreylink">{{ langConv('Contact us') }}</router-link>
                </li>
                <template v-if="$store.state.signedin && ($store.state.email == 'siobhan.trotman@hotmail.com' || $store.state.email == 'harvey.trotman@gmail.com')">
                    <li>
                        <router-link @click.native="scrollToTop" :to="{ name: 'hutchdutchbetsettlement' }" class="qgreylink" ><span>Bet Settlement</span></router-link>
                    </li>
                </template>
                <li>
                    <router-link @click.native="scrollToTop" nk :to="{ name: 'termsandconditions' }" class="qgreylink">{{ langConv('Terms and conditions') }}</router-link>
                </li>
                <li>
                    <router-link @click.native="scrollToTop" :to="{ name: 'cookiepolicy' }" class="qgreylink">{{ langConv('Cookie Policy') }}</router-link>
                </li>
                <li>
                    <router-link @click.native="scrollToTop" :to="{ name: 'privacypolicy' }" class="qgreylink">{{ langConv('Privacy Policy') }}</router-link>
                </li>
                <li>
                    <div class="qgreylink" >{{ langConv('Reset site settings to defaults') }}</div>
                </li>
                <li style="height:40px">
                        <div style="padding-top:5px;margin:auto;width:160px"><begambleaware /></div>
                </li>
                <li>
                    <div>{{ langConv('Please Gamble responsibly') }}</div>
                </li>
                <li>
                     <span class="qlighttext">{{ $store.state.ipaddress  }} - {{ $store.state.countryname }}</span>
                </li>

            </ul>

        </div>

            <!-- <div class="cell show-for-small-only footer" style="z-index:99999">
                <bannerad :bannertype="colorandBannerHTML(5)" :key="3" v-if="advertsloaded" v-if="colorandBannerHTML(5) != ''"></bannerad>
            </div> -->

  <cookiespolicy
  :debug="false"
  :disableDecline="true"
  :showPostponeButton="false"
  @clicked-accept="cookieClickedAccept"
  @clicked-decline="cookieClickedDecline"
  @clicked-postpone="cookieClickedPostpone"
  @removed-cookie="cookieRemovedCookie"
  @status="cookieStatus" 
  elementId="myPanel1"
  position="bottom"
  ref="myPanel1"
  transitionName="slideFromBottom"
  type="bar"
>

<template #message>
    {{langConv("We use cookies to ensure you get the best experience on our website")}}.
    <a href="https://oddsgalore.com/cookiepolicy" target="_blank">{{langConv('Learn More...')}}</a>
  </template>

</cookiespolicy>



</div>




</template>



<script>


import bannerad from './components/adverts/bannerad';
import menuitems from './components/components/menuitems';
import navbar from './components/navigation/navbar';
// import axios from 'axios'
import popup from './components/components/popup.vue';
import features from './components/components/features.vue'
import begambleaware from './components/adverts/begambleaware.vue'
import cookiespolicy from 'vue-cookie-accept-decline'
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';


export default {
    name: "App",
    data: function data() {
                return {
                    infotabledata: null,
                    // default odds analysis table value
                    advertsloaded: false,
                    adbanners: [],
                    bannertypes: [1, 2, 6, 5],
                    sportsloaded: false,
                    teamname: '',
                    team: ["Stevenage"],
                    graphlegend: '',
                    venue: [],
                    type: 'script',
                    initialdataloaded: false
                }
            },

            components: {

                bannerad: bannerad,
                navbar: navbar,
                menuitems: menuitems,
                popup,
                features,
                begambleaware,
                cookiespolicy
             //  autocomplete
            },

            watch:{
                $route (to, from){

                    let params = '[{ "Name": "@Type", "Value": "' + to.name + '"}, { "Name": "@Params", "Value": ' + JSON.stringify(to.params) + '},{ "Name": "@Country", "Value": "' + this.$store.state.country + '" },{ "Name": "@IPAddress", "Value": "' + this.$store.state.ipaddress + '"}]';
                    let url = process.env.VUE_APP_WEBAPI  + "SaveRetrieveandDeleteData.svc/SaveGeneric";
                    let spname = "UpdateSiteStatistics";

                    // //get bet results for that bet
                    this.newgenericpost(url, params,spname).then(response => { this.results = response});

                }
           
            },
            
            async created()  {

                 //get .json file data into VueX variables
                   
                     await this.routerlogic();

                     await this.initialdataload();

                     await this.appinit();
               
                     this.initialdataloaded = true;

                
            },

            methods: {


                async appinit() {
                    
                             //by default hints are off
                             this.$store.commit("setShowHints", false)

                             //check last session time. if > 3 hours then refresh to standard TODO
                             let currentdate = this.$store.state.sessiontime;

                             var isDate;
                             if (isNaN(Date.parse(currentdate))) {isDate = false} else {isDate = true}

                             let d = new Date();

                             //no date so put in the current one
                             if (isDate == false) { this.$store.commit("setSessionTime", d);}
                             

                             this.$store.commit("setRightSide", false); 

                             let minuteselapsed = 1;

                             //compare dates
                             if (isDate == true) {
                                let currdateasdate = new Date(currentdate);
                                let lastdate = new Date(currdateasdate.getTime()+60000*minuteselapsed);
                                if (d > lastdate) {
                                    this.logOut();
                                    this.$store.commit("setSessionTime", d);
                                }
                             }

                             //console.log("before of initialdata")

                             await this.setIPDependantDefaults();

                             //console.log("end of initialdata")

                           
                },
                    

                scrollToTop() {

                    window.scroll({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });
                 },

                //show Modal window
                showModal: function (val) {
                    this.$modal.show(val);

                },

                hidePopUp: function (val, reload) {
                    if (reload === undefined) reload = true;

                    this.$modal.hide(val);

                    //update screen (reload for mo?)
                    if (reload === true) location.reload(false);
                },

                changeteam: function () {
                    this.team[0] = this.teamname;
                    this.$forceUpdate();
                },


                async routerlogic(){

                    var site = window.location.host

                    var title = "Odds,Analysis,Promotions Website by gamblers for gamblers "
                    var desc = "Compare odds analysis, betting strategies, betting tools, gambling guides,promotional news and bookmaker trivia"

                    document.documentElement.lang = this.$store.state.country

                    // Remove any stale meta tags from the document using the key attribute we set below.
                    document.querySelectorAll('[data-vue-router-controlled]').forEach(e => e.remove());
                
                    var meta = document.createElement('meta');
            
                    meta.setAttribute('description', desc);
                    meta.setAttribute('data-vue-router-controlled', '');
                    document.getElementsByTagName('head')[0].appendChild(meta);
                    
                    this.$router.beforeResolve(async (to, from, next) => {

                    switch (to.name) {
                        case "eventodds":
                            title =  to.params.competitors.replaceAll("-"," ")
                            desc = "Odds Compared for " +  to.params.competitors.replaceAll("-"," ")
                            break;
    
                        case "events":
                            break;
                        case "sport":
                            title = to.params.sport.replaceAll("-"," ") + ": " + to.params.opencountry.replaceAll("-"," ");
                            desc = this.langConv("Select the Sports Competition(s) you want to view for") + " " + to.params.sport.replaceAll("-"," ");
                            break;
                        case "home":
                            title = this.langConv("Home");
                            desc = this.langConv("Betting odds, tips and strategy anaylsis for the amateur gambler");
                            break;
                        case "oddsanalysis":
                            title=this.langConv("Odds Analysis Reports");
                            desc=this.langConv("Analysis the odds in different ways. Bet on form teams ,interested in events where the odds move quickly and many more...");
                            break;
                        case "hutchdutchcalc":
                            title=this.langConv("All-In-One Betting Calculator");
                            desc=this.langConv("Use our super flexible bet calculator to figure out your combo bets");
                            break;
                        case "aboutsite":
                            title=this.langConv("About This Site");
                            desc=this.langConv("What we do at") + " " + site; 
                            break; 
                        case "graph":
                            title=this.langConv("Our Past Bets");
                            desc=this.langConv("Analyse the past bets of ' + site + ' users. See if they have any winning strategies or strategies that you can modify to suit");
                            break;
                        case "pendingbets":
                            title= this.langConv("Pending Bets");
                            desc= this.langConv("Have a look at the bets that the") + " " + site + " " + this.langConv("users have placed - before they are settled!");
                            break;
                        case "offers":
                            title= this.langConv("New Account Offers");
                            desc= this.langConv("Thinking of using a new bookmaker. Get the best New Account Offers from Regulated Bookmakers");
                            break;
                        case "fullpagehorsesmouth":
                            title= this.langConv("The Horses Mouth");
                            desc= this.langConv("The Lastest Bookmaker and Odds News, up to date All Punter Offers");                       
                            break;
                        case "searchresults":
                            title= " " + this.langConv("Search Results for") + " " + to.params.searchdesc 
                            desc= " " + this.langConv("Search Results for") + " " + to.params.searchdesc 
                            break;
                        case "bookmakercomparison":
                            title= this.langConv("Bookmaker's Compared");
                            desc= this.langConv("Check out what’s really important when picking a bookmaker");
                            break;
                        default:
                            title = to.name
                            desc = to.name
                            break;
                }

                document.title = title;

                // Remove any stale meta tags from the document using the key attribute we set below.
                document.querySelectorAll('[data-vue-router-controlled]').forEach(e => e.remove());
                
                var meta = document.createElement('meta');
            
                meta.setAttribute('description', desc);
                meta.setAttribute('data-vue-router-controlled', '');
                document.getElementsByTagName('head')[0].appendChild(meta);

                //TO DO - keywords are static at the moment as not sure about them 03/07/23

                // canonical link 
                var canon = document.createElement('link');
        
                canon.setAttribute('rel', 'canonical');
                canon.setAttribute('href', to.fullPath);
                canon.setAttribute('data-vue-router-controlled', '');
                document.getElementsByTagName('head')[0].appendChild(canon);

                next()
            });

            this.$router.afterEach((to) => {

                var googleid = document.querySelector('meta[name="googleid"]').getAttribute("content", googleid)
                gtag('config', googleid, {page_path: to.fullPath,app_name: site,send_page_view: true,});
                });
                },

                cookieClickedAccept: function() {},
                cookieClickedDecline: function() {},
                cookieClickedPostpone: function() {},
                cookieRemovedCookie: function() {},
                cookieStatus: function() {}


            }
        };

</script>

<style lang="scss">

@import "./style/bookmakercolour.css";
@import "./style/verbkmsmall.css";
@import "./style/bkmsmall.css";
@import "./style/sprites.css";
@import "./style/autocomplete.css";
@import "./style/bookmakercolour.css";
@import "./style/site.css";


@import './style/_settings'; //own version of the settings file not the one you get in scss directory of foundation
@import '../node_modules/foundation-sites/scss/foundation';


// Global styles

@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;
//@import '../node_modules/foundation-sites/scss/util/mixins';

// Grids (choose one)
@include foundation-xy-grid-classes;
// @include foundation-grid;
// @include foundation-flex-grid;

// Generic components
@include foundation-button;
@include foundation-button-group;
@include foundation-close-button;
@include foundation-label;
//@include foundation-progress-bar;
//@include foundation-slider;
@include foundation-switch;
@include foundation-table;
// Basic components
@include foundation-badge;
@include foundation-breadcrumbs;
//@include foundation-callout;
//@include foundation-card;
@include foundation-dropdown;
//@include foundation-pagination;//
//@include foundation-tooltip;

// Containers
//@include foundation-accordion;
//@include foundation-media-object;
//@include foundation-orbit;
//@include foundation-responsive-embed;
//@include foundation-tabs;
//@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
@include foundation-menu-icon;
//@include foundation-accordion-menu;
//@include foundation-drilldown-menu;
//@include foundation-dropdown-menu;

// Layout components
//@include foundation-off-canvas;
//@include foundation-reveal;
//@include foundation-sticky;
@include foundation-title-bar;
@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
// @include foundation-flex-classes;
@include foundation-visibility-classes;
// @include foundation-prototype-classes;





</style>