    <template>

    <div class="cell large-10 ">

        <template>
            <div class="show-for-medium">
                <div class="grid-x text-center">
                    <div class="cell large-8 medium-12"><adcarousel v-if="$store.state.countryname!=''"></adcarousel></div>
                    <div class="cell large-4 show-for-large"><horsesmouth param1="brief"></horsesmouth></div>
                </div>
            </div>
           <div class="hide-for-medium">
                <div class="grid-x text-center">
                   <div class="cell small-12" style="background-color: white;"><adcarouselsmall v-if="$store.state.countryname!=''"></adcarouselsmall></div> 
                </div>
            </div>

        </template>

        <template>

            <div class="grid-x text-center">
                    <div class="cell">
                        <menuitems  :templatetype="2"></menuitems>
                    </div>

                   <div class="cell large-8 medium-12">
                        <div style="margin:12px">
                            <tabs @changed="changeTableView">
                                <div v-for="(row,i) in $store.state.oats" :key="i">

                                    <tab :title=row.D img="" :id=row.id :tabtype="$tabtype.textonly" :requirelogin="row.requirelogin" :description="row.Explanation" v-if="row.showmain" :isactive="row.id==$store.state.selectedoat">

                                        <template v-if="row.id==$store.state.selectedoat"> 

                                            <dataviews @dvchanged="dvchange" :spname="$store.state.oats[$store.state.selectedoat].param1" :nodays=14 :sportid="sportkeyid"></dataviews>

                                        </template>

                                    </tab>
                                </div>
                            </tabs>
                        </div>
                        
                    </div>
                    <div class="cell large-4 show-for-large" style="height: 800px;margin-top:10px">

                        <template v-if="dvloaded==true">

                            <summarystats></summarystats>
                    
                            <graph searchtype="dateonly"  graphtype="Line"></graph>

                        </template>

                        <bannerad :bannertype="colorandBannerHTML(6)" v-if="colorandBannerHTML(6) != ''"></bannerad>

                    </div>
            </div>
        </template> 

</div>
</template>

 <script>

import adcarousel from '../components/adverts/adcarousel.vue';
import dataviews from  '../components/components/dataviews.vue';
import horsesmouth from '../components/features/horsesmouth.vue';
import graph from '../components/components/graph.vue';
import tab from '../components/modified3rdpartycomponents/tab.vue'
import tabs from '../components/modified3rdpartycomponents/tabs.vue'
import bannerad from '../components/adverts/bannerad';
import menuitems from '../components/components/menuitems';
import adcarouselsmall from '../components/adverts/adcarouselsmall.vue';
import summarystats from '../components/components/summarystats.vue';

    export default {
     data: function data() {
                return {
                sportkeyid: 0,
                oatloaded: false,
                dvloaded: false
                }
    },
    components: {

        adcarousel,
        dataviews,
        horsesmouth,
        graph,
        tab,
        tabs,
        bannerad,
        menuitems,
        adcarouselsmall,
        summarystats
    },

    mounted: function mounted() {

           // reset to now and next
          this.$store.commit('setOat', 0);




    },

    methods: {

            //OAT stands for Odds AnalysisType
            // populateOAT: function() {
            //         let _this = this;
            //         let params = {};
            //         let url = process.env.VUE_APP_WEBAPI  + "FeatureTables.svc/OddsAnalysisTypes";

            //          this.genericresultset(url, params).then(response => {

            //              _this.$store.commit("setOatTypes', response);

            //             _this.oatloaded = true;//

            //          },(error) => { console.log(error) }
            //         );
            // },


            changeTableView: function changeTableView(newVal) {

                      //dataview loaded - so graph loads after dataview (as things above)
                      this.dvloaded = false

                      let filteredrows = this.$store.state.oats.filter(row => row.showmain === true);

                      this.$store.commit("setOat",filteredrows[newVal].id);

                },

               refreshTableView: function refreshTableView(newVal) {
                    this.sportkeyid = newVal;
                },



            dvchange: function dvchange(newVal) {

                this.dvloaded=true

            },
             }
    }



</script>

<style scoped>

</style>