

<template>
    <span v-if="$store.state.signedin">
       <span @mouseover="mouseQuadrant" :id="uuid"   :class="[{tooltipno: hints, emptystar:!starselected,wholestar:starselected}]"  style="font-size:small"  v-on:click="starChanged" >
            <span  class="tooltiptext" :class="{'tooltip-right': tooltipfacingright}"  style="font-size:small;color:grey">Click to select as a favourite</span>
       </span>

    </span>
    <span v-else>
    
        <span @mouseover="mouseQuadrant" :id="uuid" :class="[{tooltipno: hints}]" class="emptystardisabled" v-on:click="starChanged" >
            <span class="tooltiptext" :class="{'tooltip-right': tooltipfacingright}" >Login to be able to select your favourites</span> 
        </span>
    </span>

</template>





<script>
    export default {
        name: "star",
        data: function () {
            return {
                starselected: false,
                windowWidth: window.innerWidth,
                uuid: this.$uuidv4(),
                tooltipleft: false,
                

            }
        },

        props: {
       
            cmpid: {type: Number,default: 0},
            cmpdesc: {type: String,default: ''},
            tooltipfacingright: {type: Boolean, default: false}

        },

        created() {
          
            this.starDefault();
          
        },
        computed: {
            hints () {
                return this.$store.state.showhints;
            }

        },

        mounted() {

            const el = document.getElementById(this.uuid)
            this.tooltipleft = this.tooltipdirection(el,this.windowWidth);
      
        },

        methods: {

         
                


            starDefault: function () {
               
                let existingfavs = this.$store.state.favourites;
               let val = this.cmpid;
                var index = existingfavs.findIndex(function(item, i){
                    return item.competitorid === val;
                   
                    
                });

                if (index!==-1) {this.starselected = true} else {this.starselected = false}
                
            },

            starChanged: function (val) {

                if (this.starselected == false) {this.starselected = true} else {this.starselected = false}
                
                this.setSelectedUserFavourites(this.cmpid,this.cmpdesc,this.starselected)

                this.saveuser();

            }


        }
    }
</script>

<style scoped>

.wholestar::after {
   content: "\2605"; 
   color: gold;
   font-weight:bolder
}
.emptystar::after {
    content: "\2606";
    color: gold;
    font-weight:bolder
}

.emptystardisabled::after {
    content: "☆";
    color: grey;
    font-weight:bolder
}

</style>