<!-- Vue component -->
<template>
    <div class="grid-y" style="position:relative;margin: 2px;">
        <div class="cell small-12 medium-cell-block" >
            <div :class="{loader: isloading}">

                <div :style="{display: isvisible}" v-for="(row,no) in infotabledata" :key="no">
                 
                        <!-- Brief view of blog -->

                        <div :class="{'qhighlight': no % 2}" class="qalignleft" v-if="view == 'brief'">
                            <div class="grid-x">
                            <div class="cell small-9 qsmallfontplus1">
                                <a :href="row.link" target="_blank" v-if="row.link!=null"> 
                                    
                                    <span v-if="row.img!=null"><img :src="row.img.filename" alt="" onerror="this.onerror=null;this.remove()" style="width:26px;height:26px;padding:3px"/></span><span class="qborderlink qsmallfontplus1">{{row.title}}</span> 
                                    <span v-if="row.hot" class="hot">
                                    </span>
                                </a>
                            </div>
                             
                            <div class="cell small-3 qsmallfont qgreylink">{{$displayDate(row.created,$dateformattype.daymonthtime)}}</div>
                         
                        </div>

                        <div  class="grid-x" v-on:click="showdetail(no)" v-if="row.desc != row.title">
                                <div class="cell small-10 qsmallfont">{{row.desc}}</div>
                                <div :id="'blog' + no" class="cell small-2" style="cursor: pointer;">▾</div>
                        </div>

                        <div :id="'blogdetail' + no" class="qsmallfont" style="display:none">
                            {{ row.detail }}
                        </div>

                        </div>

                        <!-- Full view of blog -->
                        <div v-if="view == 'full'">
                            <!-- :class="{'qhighlight': no % 2}" -->
                            <div class="grid-x " > 
                                <div class="cell medium-1 hide-for-small-only qhighlight" style="height:26px"><img :src="row.img.filename" alt="" class="qiconsize"/></div>
                                <div class="cell shrink qlegendfont qhighlight text-center" style="height:26px;padding-right:10px">{{$displayDate(row.created,$dateformattype.daymonthtime)}}</div> 
                                <div class="cell auto">
                                    <div class="qmediumboldfont qhighlight" style="height:26px;padding-top:2px">{{row.title}}</div>
                                    <div class="qsmallfontplus" style="color:black">{{row.desc}} - {{row.detail}}</div>
                                </div>
                                <div class="cell shrink qhighlight" style="padding-right:3px;height:26px">
                                   
                                    <actionbutton :url="String(row.link)" buttontext="Go to site" v-if="row.link!=''"></actionbutton>
                                </div>
                            </div>
                        </div>
                        
                        <div v-if="view == 'smallslider'">

                            <!-- top 4 latest storybloks -->
                            <template v-if="no<5">
                            
                                <div>Horses Mouth</div>

                                <div>{{ row.desc }}</div>

                            </template>

                        </div>


                    </div>
 
            
            </div>
      </div>
    </div>
</template>

<script>
import storyblokclient from 'storyblok-js-client'
import actionbutton from '/src/components/components/actionbutton.vue'

//headless cms -- init with access token
const storyblok = new storyblokclient({
  accessToken: '0iYQorrfamjkZs2tHsAFYQtt',
  cache: {
      clear: 'auto',
      type: 'memory'
  }
}); 


    export default {
        name: "blogview",
        data() {
            return {

                infotabledata: [],
                isloading: true,
                isvisible: 'None',
                componentinitialised: false,
                lastrowopened: -1
          
            }

        },
        mounted() {

            this.getData();
            
            //then put refresh on timer
            this.timer = setInterval(this.getData.bind(this),10000) // odds refresh every 10 secs - should be configurable (settings page)    

        },
        components: {
            actionbutton
        },
        watch: { // watcher for tracking if any props change
            $props: {
            handler() {
           
                 this.getData();
            },
            deep: true,
            immediate: true,
            }
        },
  
        props: {
            view: {type:String, default: 'brief' }, //simple all entries view
            blogsection: {type:String, default: 'all'}, // storkblok is divide into main categories types - e.g. bonus
            startdate: {type:Date,  default: function() {return new Date("1977/1/1")}}, //only show -2 days worth of stuff by default (for main screen horses mouth)
            enddate: {type:Date, default: function() {return new Date("2099/1/1")}},
            specifictext: {type:String, default: ''}
        },

        beforeDestroy() {
            clearInterval(this.timer);
        },

        methods: {

            showdetail: function(row) {

               // if (this.lastrowopened != -1 && this.detail[this.lastrowopened] == true && this.lastrowopened != row) {this.$set(this.detail, this.lastrowopened, false); document.getElementById('line' + row).innerText = "Detail ▴";}

                if (document.getElementById('blogdetail' + row).style.display == "none") {
                    document.getElementById('blog' + row).innerText = "▴";
                    document.getElementById('blogdetail' + row).style.display = "block";
                } else {
                    document.getElementById('blog' + row).innerText = "▾";
                    document.getElementById('blogdetail' + row).style.display= "none";    
                }

            }, 

            // get data for Most Popular
            getData: function () {

                let section;
                let defstartdate = new Date();
                
                //spinner whilst loads
                this.isloading = true;
                this.isvisible = "None";  

                //variable cleansing
                if (this.blogsection != '') {
                    section = this.blogsection + '/';section = section.toLowerCase()}
                if (section == 'all/') { section = ''}

                    debugger

                if (this.startdate == "1977-01-01") {
                  d =new Date();
                  let day1 = d.getDate() - 6;  
                  defstartdate = day1;
                } else {
                    defstartdate = this.startdate;
                }


                //format to storyblok format
                let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(defstartdate);
                let month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(defstartdate);
                let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(defstartdate);
                let dat = year + '-' + month + '-' + day;

                let sbstartdate = dat + " 00:00"

                debugger


                 //format to storyblok format
                year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(this.enddate);
                month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(this.enddate);
                day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(this.enddate);
                dat = year + '-' + month + '-' + day;

                let sbenddate = dat + " 00:00"
            
                storyblok.get('cdn/stories', {per_page: 100, starts_with: section, published_at_gt: sbstartdate , published_at_lt: sbenddate, search_term: this.specifictext  }) 
                    .then(response => {
                        
                        this.infotabledata = response.data.stories.map(bp => {
                      
                            return {
                                
                                title: bp.content.pithytitle,
                                desc: bp.content.description,
                                detail: bp.content.detail,
                                img: bp.content.icon,
                                link: bp.content.link,
                                created: bp.published_at,
                                section: bp.full_slug.substring(0, bp.full_slug.indexOf('/')),
                                eventid: bp.content.eventid,
                                competitionid: bp.content.competitionid,
                                bookmakerid: bp.content.bookmakerid,
                                validfrom: bp.content.validfrom,
                                validto: bp.content.validto,
                                hot: bp.content.hot,
                                bookmakername: bp.content.bookmakername
                            }

                        });

                        

                        if (this.infotabledata.length==0) {

                        
                            let img = require('/public/assets/icons/sleep.png') //convert image to binary representation
                             this.infotabledata = [{title: "Horse is asleep", img: {filename: img}, desc: "There are no entries for you current selection", link: "#"}]     
                        } else {

                        //sort so by date created
                        this.infotabledata.sort((b, a) => a.created.localeCompare(b.created));

                        //save these back to the database via web service
                        if (this.componentinitialised==false && this.infotabledata.length!=0) {

                            debugger
                           
                            this.newgenericjsonpost(process.env.VUE_APP_WEBAPI + "SaveRetrieveandDeleteData.svc/SaveGenericJSON",JSON.stringify(this.infotabledata),"SaveBlokStories_NS")
                            this.componentinitialised=true;
                        }
                        }

                        this.isloading = false;
                        this.isvisible = "block";  

                    }).catch(error => {
                        
                        console.log(error)
                    })
            }
        }

  }
</script>


