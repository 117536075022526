<template >
   <div style="height:100%">
    <div  v-if="ttype==0" style="height:100%;background-color: #D1FFBD">
    <!-- vertical sports menu -->
        <ul :class="clsname">
  
            <li v-for="(row,i) in $store.state.sports" :key="i">
         
                <router-link  :to="{name:'sport', params: {sport:row.D, opencountry: 'All' }}" style="background-color:green;margin:5px 5px 0px 5px; border-radius: 5px;line-height:22px">
                    
                        <div class="qwhiteicon" :class="row.Description" ></div>
                        <div class="qsmallfont qwhitetext" style="min-width:80px;text-align:center" v-html="langConv(row.Friendly)" ></div>
                   
                </router-link>
            </li>

    </ul>
    </div>
    <!-- horizontal sports menu items for small screens -->
    <div v-if="ttype==1" style="background-color: #D1FFBD" >
   
        <carousel  id="sportscarousel" :navigationEnabled="false" :paginationEnabled="false" :perPageCustom="[[400,3],[500,4],[600,5],[700,6],[800,7],[900,8],[1000,9],[1100,10]]" :scrollPerPage="true" v-if="$store.state.sports.length > 0">
          
            <slide v-for="(row,i) in $store.state.sports" :key="i" style="height:42px;" >
                
                <router-link :to="{name:'sport', params: {sport:row.D, opencountry: 'All' }}">
               
                    <div class="grid-x text-center" style="background-color:green;border-radius: 5px;padding:5px;line-height:22px" >
                        <div class="cell small-3 qwhiteicon" :class="row.Description"></div>
                        <div class="cell small-9 qsmallfont qwhitetext" v-html="langConv(row.Friendly)"></div>                   
                    </div>
                    
                </router-link>
              
            </slide>
            
        </carousel>
    </div>
    
    <!-- horizontal menu for most popular competitions -->
    <div class="grid-x" v-else-if="ttype==2" >
        <div class="cell shrink"></div>

        <div class="cell auto" style="margin-left:25px" >
        <carousel style="margin-top:2px;"  :navigationEnabled="true" :paginationEnabled="false" :perPageCustom="[[400,4],[480,5], [560,6], [620,7], [700,8], [780,9],[860,10],[940,10],[1020,11],[1100,12]]" :scrollPerPage="true" v-if="$store.state.popcomps !== undefined">
            <slide  v-for="(row,i) in $store.state.popcomps" :key="i" style="height:74px" >
               
                <router-link :to="{name:'events', params: {eventparams: row.CoID + '-1-1' }}" >
                    
                            <div class="qhighlight" style="padding:2px;height:69px;border-radius: 12px; border: 1px solid #e0e0e0; width:80px">
                                <div style="padding-bottom:3px"><img class="qflagsmall" :src="'/assets/images/flags/svg/' + row.Co + '.svg'"/></div> 
                                <div class="qsmallfont qborderlink text-center" style="padding:0px 0px 0px 0px;display: -webkit-box; -webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow:hidden;text-overflow: ellipsis;">{{row.Comp}}</div>
                            </div>
                            
                </router-link>
             
            </slide>
        </carousel>
        </div>
        <div class="cell shrink" style="margin-right:25px"></div>

    </div>
     
</div>
    

</template>


<script>

    import { Carousel, Slide } from 'vue-carousel';

    export default {

        name: "menuitems",
        components: {
            Carousel, Slide
        },
        data: function () {
            return {
                clsname: this.cn,
                ttype: this.templatetype
            }
        },
        props: {
            cn: String,
            templatetype: { type: Number, Default: 0 }
        }

    }
</script>

<style scoped>

    .VueCarousel-slide {
      
        height: 77px;
        text-align: center;
        padding: 3px;
        padding-top:5px;
      
    }

    .ci {
        display: block;
        margin: 0 auto;
    }

/* 
     #circularimage {
        background: #efefef;
        width: 30px;
        height: 32px;
        border-radius: 50%;
        display: flex; 
        justify-content: center;
        align-items: center;
        text-align: center; 
    
    }  */

    .speccol {
        display:table-cell;
        vertical-align:middle;
        padding-left:20px;
    }








</style>
